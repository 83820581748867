import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const Team = () => {
  const teamMembers = [
    {
      id: 1,
      name: "Urshila Chanana",
      image: "../assets/images/urshila.jpg", // Add your image path here
    },
    {
      id: 2,
      name: "Radhika Chanana",
      image: "../assets/images/radhika.jpg",
    },
    {
      id: 3,
      name: "Amira Chanana",
      image: "../assets/images/amira.jpg",
    },
    {
      id: 4,
      name: "Jaaikaran Chanana",
      image: "../assets/images/jaaikaran.jpg",
    },
  ];

  return (
    <>
      <div
        className="hero-wrap"
        style={{
          backgroundImage: `url(../assets/images/s-2.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <p
                className="breadcrumbs"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <span className="mr-2">
                  <a href="index.html">Home</a>
                </span>
                <span>Our Team</span>
              </p>
              <h1
                className="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                Our Team
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <h2 className="text-center my-4">Meet Our Team</h2>

        <Row>
          {teamMembers.map((member) => (
            <Col key={member.id} md={3} className="mb-4">
              <Card>
                <Card.Img
                  variant="top"
                  src={member.image}
                  style={{ height: "250px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="text-center">{member.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Team;
