// import React, { useState } from "react";

// const Images = () => {
//   const [selectedImage, setSelectedImage] = useState(null);

//   const images = [
//     "../assets/images/s-1.jpg",
//     "../assets/images/s-3.jpg",
//     "../assets/images/s-6.jpg",
//     "../assets/images/s-2.jpg",
//     "../assets/images/event_1.jpeg",
//     "../assets/images/event_2.jpeg",
//     "../assets/images/event_3.jpeg",
//     "../assets/images/event_4.jpeg",
//     "../assets/images/event_5.jpeg",
//     "../assets/images/event_6.jpeg",
//     "../assets/images/event_7.jpeg",
//     "../assets/images/event_8.jpeg",
//     "../assets/images/event_9.jpeg",
//     "../assets/images/event_10.jpeg",
//     "../assets/images/event_11.jpeg",
//     "../assets/images/event_12.jpeg",
//     "../assets/images/event_13.jpeg",
//     "../assets/images/event_14.jpeg",
//   ];

//   const openLightbox = (image) => {
//     setSelectedImage(image);
//     document.body.style.overflow = "hidden";
//   };

//   const closeLightbox = () => {
//     setSelectedImage(null);
//     document.body.style.overflow = "unset";
//   };
//   return (
//     <div>
//       <section className="ftco-section ftco-gallery">
//         <div>
//           <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
//             {images.map((image, index) => (
//               <div
//                 key={index}
//                 onClick={() => openLightbox(image)}
//                 style={{
//                   flex: "1 0 300px",
//                   height: "250px",
//                   position: "relative",
//                   cursor: "pointer",
//                   overflow: "hidden",
//                 }}
//                 className="gallery-item"
//               >
//                 <div
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     backgroundImage: `url(${image})`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     transition: "all 0.3s ease",
//                   }}
//                   className="gallery-image"
//                 />
//                 <div
//                   className="gallery-overlay"
//                   style={{
//                     position: "absolute",
//                     top: 0,
//                     left: 0,
//                     right: 0,
//                     bottom: 0,
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     background: "rgba(0,0,0,0)",
//                     transition: "all 0.3s ease",
//                   }}
//                 >
//                   <div
//                     className="zoom-icon"
//                     style={{
//                       width: "50px",
//                       height: "50px",
//                       borderRadius: "50%",
//                       background: "rgba(255, 255, 255, 0.9)",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       opacity: 0,
//                       transform: "scale(0.5)",
//                       transition: "all 0.3s ease",
//                     }}
//                   >
//                     <svg
//                       viewBox="0 0 24 24"
//                       width="24"
//                       height="24"
//                       stroke="currentColor"
//                       strokeWidth="2"
//                       fill="none"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     >
//                       <circle cx="11" cy="11" r="8" />
//                       <line x1="21" y1="21" x2="16.65" y2="16.65" />
//                       <line x1="11" y1="8" x2="11" y2="14" />
//                       <line x1="8" y1="11" x2="14" y2="11" />
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       {selectedImage && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundColor: "rgba(0,0,0,0.9)",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             zIndex: 1000,
//           }}
//           onClick={closeLightbox}
//         >
//           <div
//             style={{
//               position: "absolute",
//               top: "20px",
//               right: "20px",
//               color: "white",
//               fontSize: "30px",
//               cursor: "pointer",
//             }}
//           >
//             ×
//           </div>
//           <img
//             src={selectedImage}
//             alt="Enlarged view"
//             style={{
//               maxHeight: "90vh",
//               maxWidth: "90vw",
//               objectFit: "contain",
//             }}
//             onClick={(e) => e.stopPropagation()}
//           />
//         </div>
//       )}

//       <style>
//         {`
//           .gallery-item:hover .gallery-image {
//             transform: scale(1.1);
//           }

//           .gallery-item:hover .gallery-overlay {
//             background: rgba(0,0,0,0.4);
//           }

//           .gallery-item:hover .zoom-icon {
//             opacity: 1;
//             transform: scale(1);
//           }

//           .zoom-icon {
//             color: #333;
//           }

//           .zoom-icon:hover {
//             background: rgba(255, 255, 255, 1);
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default Images;
import React, { useEffect, useState } from "react";
import axios from "axios";

const Images = () => {
  const [gallery, setGallery] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    axios
      .get("https://weplayfortheworld.org/api/api/gallery/")
      .then((response) => {
        setGallery(response.data);
      })
      .catch((error) => {
        console.error("Error fetching gallery:", error);
      });
  }, []);

  const openLightbox = (media, type) => {
    setSelectedMedia(media);
    setSelectedType(type);
    document.body.style.overflow = "hidden";
  };

  const closeLightbox = () => {
    setSelectedMedia(null);
    setSelectedType("");
    document.body.style.overflow = "unset";
  };

  return (
    <div>
      <section className="ftco-section ftco-gallery">
        <div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
            {gallery.map((item) => (
              <div
                key={item.id}
                onClick={() =>
                  openLightbox(
                    item.media,
                    item.media.endsWith(".mp4") ? "video" : "image"
                  )
                }
                style={{
                  flex: "1 0 300px",
                  height: "250px",
                  position: "relative",
                  cursor: "pointer",
                  overflow: "hidden",
                  backgroundImage: item.media.endsWith(".mp4")
                    ? "none"
                    : `url(${item.media})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="gallery-item"
              >
                {item.media.endsWith(".mp4") && (
                  <video
                    src={item.media}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    muted
                    loop
                    autoPlay
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {selectedMedia && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={closeLightbox}
        >
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "white",
              fontSize: "30px",
              cursor: "pointer",
            }}
          >
            ×
          </div>
          {selectedType === "image" ? (
            <img
              src={selectedMedia}
              alt="Enlarged view"
              style={{
                maxHeight: "90vh",
                maxWidth: "90vw",
                objectFit: "contain",
              }}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <video
              src={selectedMedia}
              controls
              autoPlay
              style={{
                maxHeight: "90vh",
                maxWidth: "90vw",
                objectFit: "contain",
              }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Images;
