import React from "react";
import Form from "./Form";
import Images from "./Images";

const Gallery = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(../assets/images/event_12.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        ></div>
        <div className="container">
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            style={{ minHeight: "300px", position: "relative" }}
          >
            <div className="col-md-7 text-center">
              <p className="breadcrumbs" style={{ color: "white" }}>
                <span className="mr-2">
                  <a href="index.html" style={{ color: "white" }}>
                    Home
                  </a>
                </span>
                <span style={{ color: "white" }}> Gallery</span>
              </p>
              <h1 className="mb-3" style={{ color: "white" }}>
                Galleries
              </h1>
            </div>
          </div>
        </div>
      </div>

      <Images />

      <Form />
    </div>
  );
};

export default Gallery;
