import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [helpDropdown, setHelpDropdown] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [eventsDropdown, setEventsDropdown] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992); // Track screen size
  const location = useLocation();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://weplayfortheworld.org/api/api/categories/"
        );
        setCategories(response.data); // Store categories in state
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);
    const handleResize = () => setIsMobile(window.innerWidth <= 992);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeNavbar = () => {
    setIsNavOpen(false);
    setAboutDropdown(false);
    setHelpDropdown(false);
  };

  const isActive = (path) => (location.pathname === path ? "active" : "");

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top ${
        scrolled ? "scrolled bg-white shadow" : "navbar-dark bg-dark"
      }`}
      id="ftco-navbar"
    >
      <div className="container">
        <Link className="navbar-brand" to="/" onClick={closeNavbar}>
          <img src="../assets/images/logo.png" alt="logo" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <span className="oi oi-menu"></span> Menu
        </button>

        <div
          className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
          id="ftco-nav"
        >
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item ${isActive("/")}`}>
              <Link to="/" className="nav-link" onClick={closeNavbar}>
                Home
              </Link>
            </li>

            {/* About Dropdown (Hover on Desktop, Click on Mobile) */}
            <li
              className={`nav-item dropdown ${aboutDropdown ? "show" : ""}`}
              onMouseEnter={() => !isMobile && setAboutDropdown(true)}
              onMouseLeave={() => !isMobile && setAboutDropdown(false)}
              onClick={() => isMobile && setAboutDropdown(!aboutDropdown)}
            >
              <Link to="#" className="nav-link dropdown-toggle">
                About
              </Link>
              {aboutDropdown && (
                <ul className="dropdown-menu show">
                  <li>
                    <Link
                      to="/about"
                      className={`dropdown-item ${isActive("/about")}`}
                      onClick={closeNavbar}
                    >
                      Mission & Vision
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Team"
                      className={`dropdown-item ${isActive("/Team")}`}
                      onClick={closeNavbar}
                    >
                      Team
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* <li className={`nav-item ${isActive("/Causes")}`}>
              <Link to="/Causes" className="nav-link" onClick={closeNavbar}>
                Causes
              </Link>
            </li>
            <li className={`nav-item ${isActive("/Donate")}`}>
              <Link to="/Donate" className="nav-link" onClick={closeNavbar}>
                Donate
              </Link>
            </li> */}
            <li className={`nav-item ${isActive("/Blog")}`}>
              <Link to="/Blog" className="nav-link" onClick={closeNavbar}>
                Blog
              </Link>
            </li>
            <li className={`nav-item ${isActive("/Gallery")}`}>
              <Link to="/Gallery" className="nav-link" onClick={closeNavbar}>
                Gallery
              </Link>
            </li>
            {/* <li className={`nav-item ${isActive("/Events")}`}>
              <Link to="/Events" className="nav-link" onClick={closeNavbar}>
                Events
              </Link>
            </li> */}
            <li
              className={`nav-item dropdown ${eventsDropdown ? "show" : ""}`}
              onMouseEnter={() => setEventsDropdown(true)}
              onMouseLeave={() => setEventsDropdown(false)}
              onClick={() => setEventsDropdown(!eventsDropdown)}
            >
              <Link to="#" className="nav-link dropdown-toggle">
                Events
              </Link>
              {eventsDropdown && (
                <ul className="dropdown-menu show">
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <li key={category.id}>
                        <Link
                          to={`/events/${category.id}`} // Navigate to specific category
                          className="dropdown-item"
                        >
                          {category.name}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="dropdown-item">No Categories Available</li>
                  )}
                </ul>
              )}
            </li>
            {/* <li>
              {" "}
              <Link
                to="/Eventcrousel "
                className="nav-link"
                onClick={closeNavbar}
              >
                eventc
              </Link>
            </li> */}

            {/* Contact Us Dropdown (Hover on Desktop, Click on Mobile) */}
            <li
              className={`nav-item dropdown ${helpDropdown ? "show" : ""}`}
              onMouseEnter={() => !isMobile && setHelpDropdown(true)}
              onMouseLeave={() => !isMobile && setHelpDropdown(false)}
              onClick={() => isMobile && setHelpDropdown(!helpDropdown)}
            >
              <Link to="#" className="nav-link dropdown-toggle">
                Contact Us
              </Link>
              {helpDropdown && (
                <ul className="dropdown-menu show">
                  <li>
                    <Link
                      to="/Contact"
                      className={`dropdown-item ${isActive("/Contact")}`}
                      onClick={closeNavbar}
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Howtohelp"
                      className={`dropdown-item ${isActive("/HowWeHelp")}`}
                      onClick={closeNavbar}
                    >
                      How To Help
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
