// import React from "react";

// const Form = () => {
//   return (
//     <div>
//       {" "}
//       <section
//         class="ftco-section-3 img"
//         // style="background-image: url(images/bg_3.jpg);"
//         style={{
//           backgroundImage: `url(../assets/images/bg_3.jpg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//         }}
//       >
//         <div class="overlay"></div>
//         <div class="container">
//           <div class="row d-md-flex">
//             <div class="col-md-6 d-flex ">
//               <div
//                 class="img img-2 align-self-stretch"
//                 // style="background-image: url(images/bg_4.jpg);"
//                 style={{
//                   backgroundImage: `url(../assets/images/bg_4.jpg)`,
//                   backgroundSize: "cover",
//                   backgroundPosition: "center",
//                   backgroundRepeat: "no-repeat",
//                 }}
//               ></div>
//             </div>
//             <div class="col-md-6 volunteer pl-md-5 ">
//               <h3 class="mb-3">Be a volunteer</h3>
//               <form action="#" class="volunter-form">
//                 <div class="form-group">
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Your Name"
//                   />
//                 </div>
//                 <div class="form-group">
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Your Email"
//                   />
//                 </div>
//                 <div class="form-group">
//                   <textarea
//                     name=""
//                     id=""
//                     cols="30"
//                     rows="3"
//                     class="form-control"
//                     placeholder="Message"
//                   ></textarea>
//                 </div>
//                 <div class="form-group">
//                   <input
//                     type="submit"
//                     value="Send Message"
//                     class="btn btn-white py-3 px-5"
//                   />
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Form;

import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact_number: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  // Handle Input Change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        "https://weplayfortheworld.org/api/api/contact/",
        formData
      );
      Swal.fire({
        icon: "success",
        title: "Message Sent Successfully!",
        text: "We will get back to you soon.",
        confirmButtonColor: "#28a745",
      });
      setFormData({
        name: "",
        contact_number: "",
        email: "",
        subject: "",
        message: "",
      }); // Reset form
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to send message!",
        text: "Please try again later.",
        confirmButtonColor: "#dc3545",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <section
        className="ftco-section-3 img"
        style={{
          backgroundImage: `url(/assets/images/s-1.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row d-md-flex">
            <div className="col-md-6 d-flex">
              <div
                className="img img-2 align-self-stretch"
                style={{
                  backgroundImage: `url(/assets/images/s-3.jpg)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className="col-md-6 volunteer pl-md-5">
              <h3 className="mb-3">Be a volunteer</h3>
              <form onSubmit={handleSubmit} className="volunter-form">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="contact_number"
                    className="form-control"
                    placeholder="Your Contact Number"
                    value={formData.contact_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    cols="30"
                    rows="3"
                    className="form-control"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-white py-3 px-5"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Form;
