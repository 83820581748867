// import React from "react";
// import { Link } from "react-router-dom";

// const Blog = () => {
//   return (
//     <div>
//       <div
//         class="hero-wrap"
//         // style="background-image: url('images/bg_2.jpg');"
//         style={{
//           backgroundImage: `url(../assets/images/event_13.jpeg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           width: "100%",
//         }}
//         data-stellar-background-ratio="0.5"
//       >
//         <div class="overlay"></div>
//         <div class="container">
//           <div
//             style={{
//               backgroundColor: "rgba(0,0,0,0.5)",
//               position: "absolute",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//             }}
//           ></div>
//           <div
//             class="row no-gutters slider-text align-items-center justify-content-center"
//             data-scrollax-parent="true"
//           >
//             <div
//               class="col-md-7  text-center"
//               data-scrollax=" properties: { translateY: '70%' }"
//             >
//               <p
//                 class="breadcrumbs"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 <span class="mr-2">
//                   <Link to="index.html">Home</Link>
//                 </span>{" "}
//                 <span>Blog</span>
//               </p>
//               <h1
//                 class="mb-3 bread"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 Blog
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>

//       <section class="ftco-section">
//         <div class="container">
//           <div class="row d-flex">
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="/Blogdetail"
//                   class="block-20"
//                   //   style="background-image: url('images/image_1.jpg');"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_1.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="/Blogdetail"
//                   class="block-20"
//                   //   style="background-image: url('images/image_2.jpg');"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_2.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="/Blogdetail"
//                   class="block-20"
//                   //   style="background-image: url('images/image_3.jpg');"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_3.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="/Blogdetail"
//                   class="block-20"
//                   //   style="background-image: url('images/image_4.jpg');"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_4.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="/Blogdetail"
//                   class="block-20"
//                   //   style="background-image: url('images/image_5.jpg');"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_5.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="/Blogdetail"
//                   class="block-20"
//                   //   style="background-image: url('images/image_6.jpg');"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_6.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="row mt-5">
//             <div class="col text-center">
//               <div class="block-27">
//                 <ul>
//                   <li>
//                     <Link to="#">&lt;</Link>
//                   </li>
//                   <li class="active">
//                     <span>1</span>
//                   </li>
//                   <li>
//                     <Link to="#">2</Link>
//                   </li>
//                   <li>
//                     <Link to="#">3</Link>
//                   </li>
//                   <li>
//                     <Link to="#">4</Link>
//                   </li>
//                   <li>
//                     <Link to="#">5</Link>
//                   </li>
//                   <li>
//                     <Link to="#">&gt;</Link>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Blog;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";

// const Blog = () => {
//   const [blogs, setBlogs] = useState([]);

//   useEffect(() => {
//     axios
//       .get("https://weplayfortheworld.org/api/api/blogs/1/")
//       .then((response) => {
//         setBlogs([response.data]); // Convert single object response to an array
//       })
//       .catch((error) => console.error("Error fetching blog data:", error));
//   }, []);

//   return (
//     <div>
//       {/* Hero Section */}
//       <div
//         className="hero-wrap"
//         style={{
//           backgroundImage: `url(../assets/images/event_13.jpeg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           width: "100%",
//         }}
//         data-stellar-background-ratio="0.5"
//       >
//         <div className="overlay"></div>
//         <div className="container">
//           <div
//             style={{
//               backgroundColor: "rgba(0,0,0,0.5)",
//               position: "absolute",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//             }}
//           ></div>
//           <div
//             className="row no-gutters slider-text align-items-center justify-content-center"
//             data-scrollax-parent="true"
//           >
//             <div
//               className="col-md-7 text-center"
//               data-scrollax=" properties: { translateY: '70%' }"
//             >
//               <p
//                 className="breadcrumbs"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 <span className="mr-2">
//                   <Link to="/">Home</Link>
//                 </span>
//                 <span>Blog</span>
//               </p>
//               <h1
//                 className="mb-3 bread"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 Blog
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Blog List Section */}
//       <section className="ftco-section">
//         <div className="container">
//           <div className="row d-flex">
//             {blogs.map((blog) => (
//               <div className="col-md-4 d-flex" key={blog.id}>
//                 <Link to={`/Blogdetail/${blog.id}`}>
//                   <div className="blog-entry align-self-stretch">
//                     <Link
//                       to={`/Blogdetail/${blog.id}`}
//                       className="block-20"
//                       style={{
//                         backgroundImage: `url(${blog.image})`,
//                         backgroundSize: "cover",
//                         backgroundPosition: "center",
//                         backgroundRepeat: "no-repeat",
//                         width: "100%",
//                         height: "250px",
//                       }}
//                     ></Link>
//                     <div className="text p-4 d-block">
//                       <div className="meta mb-3">
//                         <div>
//                           <Link to="#">{blog.date}</Link>
//                         </div>
//                         <div>
//                           <Link to="#">Admin</Link>
//                         </div>
//                         {/* <div>
//                         <Link to="#" className="meta-chat">
//                           <span className="icon-chat"></span> 3
//                         </Link>
//                       </div> */}
//                       </div>
//                       <h3 className="heading mt-3">
//                         <Link to={`/Blogdetail/${blog.id}`}>{blog.title}</Link>
//                       </h3>
//                       <p>{blog.hint}</p>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Blog;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6; // Number of blogs per page

  useEffect(() => {
    axios
      .get("https://weplayfortheworld.org/api/api/blogs")
      .then((response) => {
        setBlogs(response.data); // Assuming API returns an array
      })
      .catch((error) => console.error("Error fetching blog data:", error));
  }, []);

  // Calculate total pages
  const totalPages = Math.ceil(blogs.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div>
      {/* Hero Section */}
      <div
        className="hero-wrap"
        style={{
          backgroundImage: `url(../assets/images/event_13.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <p
                className="breadcrumbs"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <span className="mr-2">
                  <Link to="/">Home</Link>
                </span>
                <span>Blog</span>
              </p>
              <h1
                className="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                Blog
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Blog List Section */}
      <section className="ftco-section">
        <div className="container">
          <div className="row d-flex">
            {currentBlogs.map((blog) => (
              <div className="col-md-4 d-flex" key={blog.id}>
                <Link to={`/Blogdetail/${blog.id}`}>
                  <div className="blog-entry align-self-stretch">
                    <Link
                      to={`/Blogdetail/${blog.id}`}
                      className="block-20"
                      style={{
                        backgroundImage: `url(${blog.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: "250px",
                      }}
                    ></Link>
                    <div className="text p-4 d-block">
                      <div className="meta mb-3">
                        <div>
                          <Link to="#">{blog.date}</Link>
                        </div>
                        <div>
                          <Link to="#">Admin</Link>
                        </div>
                      </div>
                      <h3 className="heading mt-3">
                        <Link to={`/Blogdetail/${blog.id}`}>{blog.title}</Link>
                      </h3>
                      {/* <p>{blog.hint}</p> */}
                      <p>
                        {blog.hint.length > 60
                          ? `${blog.hint.substring(0, 60)}...`
                          : blog.hint}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="row mt-5">
              <div className="col text-center">
                <div className="block-27">
                  <ul className="">
                    {/* Previous Button */}
                    <li className={currentPage === 1 ? "disabled" : ""}>
                      <span
                        onClick={() => handlePageChange(currentPage - 1)}
                        style={{
                          cursor: currentPage === 1 ? "not-allowed" : "pointer",
                        }}
                      >
                        &lt;
                      </span>
                    </li>

                    {/* Page Numbers */}
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={currentPage === index + 1 ? "active" : ""}
                      >
                        <span onClick={() => handlePageChange(index + 1)}>
                          {index + 1}
                        </span>
                      </li>
                    ))}

                    {/* Next Button */}
                    <li
                      className={currentPage === totalPages ? "disabled" : ""}
                    >
                      <span
                        onClick={() => handlePageChange(currentPage + 1)}
                        style={{
                          cursor:
                            currentPage === totalPages
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        &gt;
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Blog;
