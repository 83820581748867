import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/mainpages/Header";
import Home from "./Components/pages/Home";
import Footer from "./Components/mainpages/Footer";
import About from "./Components/pages/About";
import Contact from "./Components/pages/Contact";
import Events from "./Components/pages/Events";
import Form from "./Components/pages/Form";
import Gallery from "./Components/pages/Gallery";
import Blog from "./Components/pages/Blog";
import Causes from "./Components/pages/Causes";

import Blogdetail from "./Components/pages/Blogdetail";
import Team from "./Components/pages/Team";
import Howtohelp from "./Components/Insidepages/Howtohelp";
import Howtohelpdetail from "./Components/Insidepages/Howtohelpdetail";
import Images from "./Components/pages/Images";
import Hero from "./Components/pages/Hero";
import Eventcrousel from "./Components/pages/Eventcrousel";
import Homeblog from "./Components/pages/Homeblog";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Hero" element={<Hero />} />
        <Route path="/About" element={<About />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Causes" element={<Causes />} />

        <Route path="/Blog" element={<Blog />} />
        <Route path="/Blogdetail/:blogId" element={<Blogdetail />} />
        <Route path="/Homeblog" element={<Homeblog />} />
        <Route path="/Eventcrousel" element={<Eventcrousel />} />
        {/* <Route path="/Events" element={<Events />} /> */}
        <Route path="/events/:categoryId" element={<Events />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Images" element={<Images />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Howtohelp" element={<Howtohelp />} />
        <Route path="/Howtohelpdetail" element={<Howtohelpdetail />} />
        <Route path="/Form" element={<Form />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
